import type { Ref } from 'vue'
import type { MisStatisticItemDto } from '~/api/misStatistics'
import { ASYNC_DATA_KEYS } from '~/constants'

export const useMisListData = () => {
  const { data } = useNuxtData<MisStatisticItemDto[]>(ASYNC_DATA_KEYS.misList)

  if (!data.value) {
    throw createError({
      statusCode: 500,
      message: 'useMisListData: данные не были получены'
    })
  }

  return {
    misList: data as Ref<MisStatisticItemDto[]>
  }
}
